'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classnames from "classnames";
import Form from "react-bootstrap/Form";
import {isFalse, isEmpty} from "@uw-it-sis/lib-react/lib/Utils";
import {ProtocolLabels, RegEx} from "./Constants";
import {phoneDigits} from "./Utils";

class ContactField extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.isInvalid = false
        this.state = {
            value: this.props.value,
            isInvalid: this.isInvalid
        }

        this.validate = this.validate.bind(this);
        this.setValidity = this.setValidity.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    validate(value) {
        let inputValue = value.trim();
        let validRegEx;
        switch (this.props.type) {
            case "email":
                validRegEx = RegEx.email
                break;
            case "sms":
                validRegEx = RegEx.phone
                break;
        }
        const isValueInvalid = isFalse(validRegEx.test(inputValue));
        const isValueEmpty = (inputValue === "" || inputValue === null);

        let isInvalid = (isValueEmpty ? false : isValueInvalid);

        this.isInvalid = isInvalid;
        this.props.validityCallback(isInvalid);
    }

    setValidity() {
        this.setState({isInvalid: this.isInvalid});
    }

    onChange(e) {
        e.stopPropagation();
        const value = e.currentTarget.value;
        this.props.onChange(e);
        this.setState({value: value});
        this.validate(value);

        if (this.state.isInvalid) {
            this.setValidity();
        }
    }

    render() {
        let control;
        let additionalLabel;

        switch (this.props.type) {
            case "email":
                control =
                    <div>
                        <Form.Control
                            name={this.props.type}
                            type="email"
                            style={this.props.style}
                            placeholder="e.g. netid@uw.edu"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onBlur={this.setValidity}
                            onChange={this.onChange}
                            aria-labelledby={`input-label-${this.props.type}`}
                            aria-invalid={this.state.isInvalid}
                            isInvalid={this.state.isInvalid}
                            value={this.state.value} />
                        <Form.Control.Feedback type="invalid" aria-live="polite">
                            Not a valid email address format.
                        </Form.Control.Feedback>
                    </div>;
                break;
            case "sms":
                let inputCssClasses = classnames(
                    "form-control",
                    {"is-invalid": this.state.isInvalid}
                )

                control =
                    <div>
                        <PatternFormat
                            name={this.props.type}
                            type="tel"
                            className={inputCssClasses}
                            style={this.props.style}
                            format="(###) ###-####"
                            mask="_"
                            value={phoneDigits(this.state.value)}
                            valueIsNumericString
                            onChange={this.onChange}
                            onBlur={this.setValidity}
                            aria-labelledby={`input-label-${this.props.type}`}
                            aria-invalid={this.state.isInvalid}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            placeholder="e.g. (206) 123-4567"
                        />
                        <Form.Control.Feedback type="invalid" aria-live="polite">
                            Not a valid US mobile number.
                        </Form.Control.Feedback>
                    </div>
                additionalLabel = <span className="ms-2 fw-normal">(US mobile number only)</span>;
                break;
            default:

        }

        let errorLabel;
        if (!isEmpty(this.props.errorMessage)) {
            errorLabel = <span className="text-danger">{this.props.errorMessage}</span>;
        }

        return (
            <Form.Group controlId={`input-${this.props.type}`} className={classnames(
                {"d-flex align-items-center mb-0": this.props.inline}
            )}>
                <Form.Label id={`input-label-${this.props.type}`} className={classnames("fw-bold mb-0")}>
                    <span>{ProtocolLabels[this.props.type].text}</span>
                    {additionalLabel}
                </Form.Label>
                {control}
                {errorLabel}
            </Form.Group>
        );
    }
}

ContactField.propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    validityCallback: PropTypes.func,
    inline: PropTypes.bool,
    value: PropTypes.string,
    style: PropTypes.object,
    errorMessage: PropTypes.string,
}

ContactField.defaultProps = {
    value: "",
    inline: false,
    validityCallback: () => {}
}

export default ContactField;