'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";
import PropTypes from 'prop-types';
import {isEmpty as underscoreIsEmpty} from 'underscore';
import moment from "moment";
import classnames from "classnames"

import {FaRegCommentDots, FaRegEnvelope} from "react-icons/fa";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";

import TermUtils from "@uw-it-sis/lib-react/lib/TermUtils";
import {isEmpty, pluralize, daysOfWeekAbbreviation, isFalse} from "@uw-it-sis/lib-react/lib/Utils";

import SubscriptionStore from './SubscriptionStore';
import SubscriptionForm from "./SubscriptionForm";
import SectionCode from "./utils/SectionCode";
import events from "@uw-it-sis/lib-react/lib/Events";
import {NotifyEvents} from "./utils/Constants";
import {phoneFormat} from "./utils/Utils";
import ProfileStore from "./ProfileStore";

class Subscription extends Component {

    constructor(props) {
        super(props);

        this.props = props;
        this.store = SubscriptionStore;

        this.state = { showEditModal: false, showDeleteModal: false };
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    }

    componentDidMount() {
        events.listen(NotifyEvents.subscriptionUpdated, () => {
            this.setState({showEditModal: false});
        });
    }

    componentWillUnmount() {
        events.remove(NotifyEvents.subscriptionUpdated, () => {
            this.setState({showEditModal: false});
        });
    }

    toggleEditModal() {
        this.setState({showEditModal: !this.state.showEditModal});
    }

    toggleDeleteModal() {
        this.setState({showDeleteModal: !this.state.showDeleteModal});
    }

    _deleteSubscription(e) {
        e.stopPropagation();
        this.toggleDeleteModal();

        this.store.deleteByChannel(this.props.subscription);
    }

    render() {
        let subscriptionDataDisplay;

        let subscription = this.props.subscription;

        let activityCode = `${subscription.courseCode} ${subscription.activityCode}`;

        if (subscription.hasOwnProperty("section") && !isEmpty(subscription.section)) {
            let meetings;
            if (subscription.section.hasOwnProperty("meetingDetailsList") && subscription.section.meetingDetailsList.length > 0) {
                meetings = subscription.section.meetingDetailsList.map((offering, i) => {
                    // If days and time are empty, its TBA
                    let daysAndTimesEmpty = true;
                    [offering.days, offering.time].forEach(test => {
                        if ( daysAndTimesEmpty && ! (isEmpty(test) || underscoreIsEmpty(test.trim())) ) {
                            daysAndTimesEmpty = false;
                        }
                    });

                    if (daysAndTimesEmpty) {
                        return (
                            <Row key={i} className="g-0">
                                <Col>Meeting time and location: --</Col>
                            </Row>
                        );
                    } else {
                        let location;
                        if (offering.building) {
                            location = <>{offering.building} <span className="sr-only"> building room </span> {offering.room}</>;
                        }

                        let time = offering.time;
                        if ( /\d{1,2}:\d{2}\s[apmAPM]{2}\s\W\s\d{1,2}:\d{2}\s[apmAPM]{2}/.test(time) ) {
                            let times = time.split(" - ");
                            time =
                                <>
                                    <time dateTime={moment(times[0], "h:mm A").format("HH:mm")}>{times[0]}</time>
                                    <span> - </span>
                                    <time dateTime={moment(times[1], "h:mm A").format("HH:mm")}>{times[1]}</time>
                                </>
                        }

                        return (
                            <Row key={i} className="g-0">
                                <Col xs={3} className="text-sm-start">{daysOfWeekAbbreviation(offering.days)}</Col>
                                <Col xs={6} className="text-sm-center">{time}</Col>
                                <Col xs={3} className="text-sm-end">{location}</Col>
                            </Row>
                        );
                    }
                });
            }

            // Build array of all instructors names without duplicates
            let allInstructors = subscription.section.allInstructors.map(instructor => instructor.name).filter((v, i, s) => s.indexOf(v) === i);
            let instructor;
            if (allInstructors.length > 0) {
                instructor =
                    <Row className="g-0">
                        <Col>{pluralize("Instructor", allInstructors)}: {allInstructors.join(", ")}</Col>
                    </Row>;
            }



            let summerBadge;
            if ( subscription.quarter.toUpperCase() === TermUtils.TERMS[3].toUpperCase() && subscription.section.summerTerm ) {
                summerBadge =
                    <>
                        <span className="sr-only">Summer Term: </span>
                        <Badge bg="light-gray" className="text-dark fs-9 text-uppercase fw-normal border ms-2">{subscription.section.summerTerm}</Badge>
                    </>;
            }

            let enrollStatus;
            if ( subscription.section.enrollStatus ) {
                let enrollStatusValue;
                switch ( subscription.section.enrollStatus.toLowerCase() ) {
                    case 'open':
                    case 'closed':
                        enrollStatusValue = subscription.section.enrollStatus;
                        break;
                    default:
                        enrollStatusValue = "--";
                }

                let enrollBadgeVariant;
                switch (enrollStatusValue) {
                    case "closed":
                        enrollBadgeVariant = "danger";
                        break;
                    case "open":
                        enrollBadgeVariant = "success";
                        break;
                    default:
                        enrollBadgeVariant = "white";
                }

                enrollStatus =
                    <Badge bg={enrollBadgeVariant} className="fs-9 text-uppercase">
                        {enrollStatusValue}
                    </Badge>;
            }

            subscriptionDataDisplay =
                <div className="flex-grow-1">
                    <div className="d-flex align-items-start">
                        <div className="sectionCode text-nowrap" style={{width: "48px", minWidth: "48px"}}>
                            <SectionCode section={subscription.section} />
                        </div>
                        <Row className="g-0 flex-grow-1 d-flex align-items-center">
                            <Col xs={6} sm={8}>
                                <span className="sr-only"><abbr title="Schedule Line Number">SLN</abbr>: </span>
                                <a href={`https://sdb.admin.washington.edu/timeschd/uwnetid/sln.asp?QTRYR=${subscription.section.qtryr}&SLN=${subscription.section.registrationCode}`}
                                   className="align-middle me-2"
                                   target="_blank">
                                    {subscription.section.registrationCode}
                                </a>
                                <span className="sr-only">Section Type: </span>
                                <Badge bg="light-gray" className="text-dark align-middle fs-9 text-uppercase fw-normal border">
                                    {subscription.section.activityOfferingType}
                                </Badge>
                                {summerBadge}
                            </Col>
                            <Col xs={6} sm={4} className="text-end text-nowrap">
                                <span className="sr-only">Enrollment Status: </span>
                                {enrollStatus}
                                <Badge bg="light-gray" className="text-dark fs-9 text-uppercase fw-normal border">
                                    <span>{subscription.section.enrollCount} </span>
                                    <span className="sr-only">seats </span>
                                    <span className="sr-only">out </span>
                                    <span>of {subscription.section.enrollMaximum}</span>
                                    <span className="sr-only">seats</span>
                                </Badge>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-2" style={{paddingLeft: "48px"}}>
                        {meetings}
                        {instructor}
                    </div>
                </div>;
        } else {
            subscriptionDataDisplay =
                <div className="flex-grow-1">
                    <div className="d-flex align-items-start">
                        <div className="sectionCode text-nowrap" style={{width: "48px", minWidth: "48px"}}>
                            {subscription.activityCode}
                        </div>
                        <Row className="g-0 flex-grow-1 d-flex align-items-center">
                            <Col xs={12} sm={8}>
                                <span className="sr-only"><abbr title="Schedule Line Number">SLN</abbr>: </span>
                                {subscription.sln}
                            </Col>
                        </Row>
                    </div>
                </div>;
        }

        let endpointsSubscribed = subscription.endpoints.map((endpoint, i) => {
            let Icon;
            let address = endpoint.address;
            let isBlocked = endpoint.hasOwnProperty("Active") && isFalse(endpoint.Active);
            switch (endpoint.type) {
                case "email":
                    Icon = FaRegEnvelope;
                    isBlocked = ProfileStore.isEmailEndpointBlocked();
                    break;
                case "sms":
                    Icon = FaRegCommentDots;
                    address = phoneFormat(endpoint.address);
                    break;
                default:
                    return null;
            }

            return <Icon key={i} className={classnames("fs-3", {"text-muted": isBlocked})} title={`Notification will be sent to ${address}`} aria-hidden={true} />;
        });

        return (
            <>
                <li className="d-sm-flex align-items-sm-start">
                    {subscriptionDataDisplay}
                    <div className="mt-3 mt-sm-0 ps-0 ps-md-4 ms-md-4 d-grid gap-2 border-start-md">
                        <Button variant="secondary"
                                size="sm"
                                title={`Remove Notification for: ${activityCode}`}
                                onClick={this.toggleDeleteModal}>
                            Remove Notification
                        </Button>
                        <Button variant="link"
                                aria-haspopup={true}
                                className="py-1 px-0 space-x-4 text-start"
                                title={`Edit Subscription for ${activityCode}`}
                                onClick={this.toggleEditModal}>
                            {endpointsSubscribed}
                        </Button>
                    </div>
                </li>
                <Modal show={this.state.showEditModal}
                       onHide={this.toggleEditModal}
                       aria-labelledby="subscription-edit-modal-title"
                       centered
                       scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title as="h3" id="subscription-edit-modal-title">Update Seat Availability Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Change how you'd like to receive this seat availability notification.</p>
                        <SubscriptionForm closeForm={this.toggleEditModal}
                                          subscription={subscription}>
                            {this.props.courseDisplay}
                            {subscriptionDataDisplay}
                        </SubscriptionForm>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showDeleteModal}
                       onHide={this.toggleDeleteModal}
                       aria-labelledby="subscription-delete-modal-title"
                       centered
                       scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title id="subscription-delete-modal-title">Remove {activityCode} Seat Availability Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Stop receiving seat availability notifications for <strong>{activityCode}</strong> (SLN: {subscription.sln}) for {subscription.quarter} {subscription.year}.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end">
                        <Button variant="secondary" onClick={this.toggleDeleteModal}>Cancel</Button>
                        <Button variant="primary" onClick={(e) => this._deleteSubscription(e)}>Remove Notification</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

Subscription.propTypes = {
    subscription: PropTypes.object.isRequired,
    courseDisplay: PropTypes.node
};

Subscription.defaultProps = {
    courseDisplay: null
}

export default observer(Subscription);