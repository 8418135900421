'use strict';

import React, {Component} from 'react';
import {observer} from 'mobx-react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';

import Profile from './Profile';
import Subscriptions from './Subscriptions';
import notifyStore from './NotifyStore';
import profileStore from './ProfileStore';

class Notify extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.store = notifyStore;
    }

    componentDidMount() {
        this.store.mount();
    }

    componentWillUnmount() {
        this.store.unmount();
    }

    _buildComponent() {
        if (profileStore.hasProfile() && profileStore.hasEndpoints()) {
            return (
                <>
                    <h1 className="mt-4">Notifications</h1>
                    <Row>
                        <Col xs={12} lg={8}>
                            <Subscriptions />
                        </Col>
                        <Col xs={12} lg={4}>
                            <Profile />
                        </Col>
                    </Row>
                </>
            );
        } else {
            return (
                <Profile/>
            );
        }
    }

    render() {
        return (
            <SimpleState
                pendingComponentBuilder="dots"
                state={this.store.state}
                doneComponentBuilder={() => this._buildComponent()}
                name="Notify" />
        );
    }
}

export default observer(Notify);