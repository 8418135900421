'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {FaRegBell, FaRegFileAlt, FaKey} from "react-icons/fa";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import {groupBy, pluralize} from "@uw-it-sis/lib-react/lib/Utils";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import events from "@uw-it-sis/lib-react/lib/Events";

import Subscription from "./Subscription";
import NewSubscriptionForm from "./NewSubscriptionForm";
import {NotifyEvents} from "./utils/Constants";
import {getSectionLegendFlags} from "./utils/Utils";

class SubscriptionsTerm extends Component  {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            showAddDialog: false
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        events.listen(NotifyEvents.subscriptionsChanged, () => {
            this.setState({showAddDialog: false});
        });
    }

    componentWillUnmount() {
        events.remove(NotifyEvents.subscriptionsChanged, () => {
            this.setState({showAddDialog: false});
        });
    }

    toggleModal() {
        this.setState({showAddDialog: !this.state.showAddDialog});
    }

    render() {
        const termName = this.props.term.name;
        let cardBody, cardFooter;

        const newSubscriptionForm = <NewSubscriptionForm year={this.props.term.year} quarter={this.props.term.codeName} incomingSln={this.props.incomingSln} />;

        if (this.props.subscriptions.length > 0) {
            const myplanHost = this.context.appStore.getAppAttribute('myplanUrl');
            const subscriptionsGroupedByCourse = groupBy(this.props.subscriptions, 'courseCode');
            let legendAddCode, legendEnrollRestriction;

            const courses = Object.entries(subscriptionsGroupedByCourse).map(([course, subs], i) => {
                let courseDetails = {
                    code: course,
                    title: subs[0].courseTitle,
                    credits: subs[0].section.credits,
                    url: `${myplanHost}/course/#/courses/${course}`
                };

                if (subs[0].hasOwnProperty("section") && subs[0].section.hasOwnProperty("courseId") && subs[0].section.courseId) {
                    courseDetails.url = `${myplanHost}/course/#/courses/${course}?id=${subs[0].section.courseId}`;
                }

                let courseDataDisplay =
                    <div className="mb-2">
                        <h3 className="h4 mb-0 d-inline align-middle">
                            <a href={courseDetails.url}>{courseDetails.code}</a>
                        </h3>
                        <Badge bg="light-gray" className="text-dark align-middle fs-9 text-uppercase fw-normal border ms-2" aria-hidden="true">{courseDetails.credits} <abbr title="Credit">CR</abbr></Badge>
                        <a href={courseDetails.url} className="d-block lead me-4">{courseDetails.title}</a>
                        <span className="sr-only">{courseDetails.credits} {pluralize("Credit", courseDetails.credits)}</span>
                    </div>;

                const legends = getSectionLegendFlags(subs);

                if (legends.addCode) {
                    legendAddCode = <small className="d-block text-end lh-1"><FaKey className="me-1" aria-hidden={true} /> Section requires entry code</small>;
                }
                if (legends.enrollRestriction) {
                    legendEnrollRestriction = <small className="d-block text-end lh-1"><FaRegFileAlt className="me-1" aria-hidden={true} /> Section has enrollment restrictions</small>;
                }

                let subscriptionList = subs.sort((a, b) => {
                    if (a.activityCode < b.activityCode) return -1;
                    if (a.activityCode > b.activityCode) return 1;
                    return 0;
                }).map(( sub, x) => {
                    return <Subscription key={x} subscription={sub} courseDisplay={courseDataDisplay} />;
                });

                return (
                    <ListGroup.Item key={i} as="li">
                        {courseDataDisplay}
                        <ul className="list-unstyled space-y-3">
                            {subscriptionList}
                        </ul>
                    </ListGroup.Item>
                );
            });

            cardBody =
                <>
                    {legendAddCode}
                    {legendEnrollRestriction}
                    <ListGroup as="ul" className="mt-2 list-group-flush">
                        {courses}
                        <ListGroup.Item key={courses.length} as="li">
                            {newSubscriptionForm}
                        </ListGroup.Item>
                    </ListGroup>
                </>;

            /*
            cardFooter =
                <Card.Footer className="bg-transparent">
                    <p>Want to track seat availability for other sections? Add more seat availability notifications.</p>
                    <Button className="d-inline-flex align-items-center"
                            title="Add a seat notification"
                            onClick={this.toggleModal}>
                        <FaRegBell aria-hidden={true} className="me-2"/>
                        <span>Add Notification</span>
                    </Button>
                    <Modal show={this.state.showAddDialog}
                           onHide={this.toggleModal}
                           aria-labelledby="create-subscription-modal-title"
                           centered
                           scrollable>
                        <Modal.Header closeButton>
                            <Modal.Title id="create-subscription-modal-title" as="h3">Add Notification</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <NewSubscriptionForm year={this.props.term.year} quarter={this.props.term.codeName} isModal />
                        </Modal.Body>
                    </Modal>
                </Card.Footer>;
             */
        } else {
            cardBody = newSubscriptionForm;
        }

        return (
            <Card border="border">
                <Card.Header className="bg-transparent">
                    <Card.Title as="h2" className="mb-0">{termName}</Card.Title>
                </Card.Header>
                <Card.Body className={classnames({"pt-0":this.props.subscriptions.length > 0})}>
                    {cardBody}
                </Card.Body>
            </Card>
        );
    }
}

SubscriptionsTerm.propTypes = {
    term: PropTypes.object.isRequired,
    subscriptions: PropTypes.array.isRequired,
    incomingSln: PropTypes.string
}

export default SubscriptionsTerm;