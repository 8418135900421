'use strict';

import { observable, configure, flow, action, makeObservable } from 'mobx';
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import {isEmpty, isFalse} from "@uw-it-sis/lib-react/lib/Utils";
import AnnouncerStore from "@uw-it-sis/lib-react/lib/AnnouncerStore";

import api from "./utils/Api";
import {NotifyEvents, RegEx} from "./utils/Constants";
import {phoneDigits} from "./utils/Utils";
import events from "@uw-it-sis/lib-react/lib/Events";

class ProfileStore {
    state = States.initial;

    profile = {};

    formData = {};

    initialData = {};

    showEndpointModal = false;

    updatedUnblockState = false;

    endpointTransaction = {};

    constructor() {
        makeObservable(this, {
            state: observable,
            profile: observable,
            formData: observable,
            showEndpointModal: observable,
            updatedUnblockState: observable,
            endpointTransaction: observable,
            mount: action,
            resetFormData: action,
            updateFormData: action,
            toggleEndpointModal: action,
            setShowEndpointModal: action
        });
    }

    mount() {
        this.state = States.pending;
        this._loadProfile();
        this.endpointTransaction = {
            // Using the 'done' state as a 'ready to go' state for the transaction
            state: States.done,
            errorMessage: {
                email: "",
                sms: ""
            }
        }
    }

    _loadProfile = flow(function* () {
        try {
            this.profile = yield api.getProfile();

            if (appStore.isDebug()) {
                console.log("ProfileStore:loadProfile completed loading profile")
            }

            //initialize / reset formdata
            this.resetFormData();

            this.state = States.done;

        }
        catch (error) {
            this.state = States.error;
        }
    }.bind(this));


    resetFormData() {
        // default email protocol form data to UW NetID email address
        if ( isFalse(this.hasEndpoints()) && isFalse(this.hasEndpoint('email')) ) {
            this.formData.email = `${appStore.getUserName()}@uw.edu`;
            // Since the user has no endpoints at all, this is most likely a First-time user.
            // Default the initialData to the empty string to represent that they have no
            // email stored. This will let them submit the "default" netid.
            this.initialData.email = "";
        } else {
            this.formData.email = this.getEmailEndpoint().EndpointAddress;
            this.initialData.email = this.getEmailEndpoint().EndpointAddress;

            // if there isn't a transaction in progress, and there's a valid address found from the retrieved data,
            // clear any related errors
            if (this.endpointTransaction.state !== States.pending) {
                this.endpointTransaction.errorMessage.email = "";
            }
        }

        if ( this.hasEndpoint('sms') && this.getSmsEndpoint().EndpointAddress) {
            this.formData.sms = phoneDigits(this.getSmsEndpoint().EndpointAddress);
            this.initialData.sms = phoneDigits(this.getSmsEndpoint().EndpointAddress);

            // if there isn't a transaction in progress, and there's a valid phone number found from the retrieved data,
            // clear any related errors
            if (this.endpointTransaction.state !== States.pending) {
                this.endpointTransaction.errorMessage.sms = "";
            }
        } else {
            this.formData.sms = "";
            this.initialData.sms = "";
        }
    }

    createProfile = flow(function* () {
        this.state = States.pending;

        // The data for the surrogate field follows a common pattern in the data, but its significance is lost to the ages
        // The email endpoint is a separate value
        let profile = { "surrogate": `${appStore.getUserName()}@washington.edu` };

        try {
            yield api.createProfile(profile);

            if (appStore.isDebug()) {
                console.log("ProfileStore:createProfile completed create request")
            }

            this._loadProfile();
        }
        catch (error) {
            this.state = States.error;
        }
    }.bind(this));



    updateEndpoint = flow(function* (endpoint) {
        this.state = States.pending;

        this.persistEndpoint(endpoint);

        if (this.state === States.pending) {
            try {
                if (appStore.isDebug()) {
                    console.log("ProfileStore:updateEndpoint completed update request")
                }

                this._loadProfile();
            }
            catch (error) {
                this.state = States.error;
            }
        }
    }.bind(this));

    /**
     * Creates or updates the given endpoint through the API
     */
    persistEndpoint = flow(function * (endpoint, forceCreate = false) {

        this.endpointTransaction.state = States.pending;
        this.endpointTransaction.errorMessage[endpoint.Protocol] = "";

        try {
            if ( forceCreate || ( isFalse(endpoint.hasOwnProperty("EndpointID")) &&
                                  isFalse(this.hasEndpoint(endpoint.Protocol)) ) ) {
                yield api.createProfileEndpoint(endpoint);
            } else {
                yield api.updateProfileEndpoint(endpoint);
            }

            this.endpointTransaction.state = States.done;
        }
        catch (error) {

            this.endpointTransaction.state = States.error;

            // if we get a standard validation error ServiceException
            if (error.exceptionCode === "VALIDATION_ERROR" && error.expected === true) {
                // we can use the error message directly
                this.endpointTransaction.errorMessage[endpoint.Protocol] = error.message;
            }
            else {
                // any other exception set the general error state as well
                this.state = States.error;
            }
        }

    }.bind(this));

    updateFormData(protocol, value) {
        this.state = States.pending;
        this.formData[protocol] = value;
        this.state = States.done;
    }

    getFormData(protocol) {
        if (this.formData.hasOwnProperty(protocol)) {
            return this.formData[protocol];
        } else {
            return "";
        }
    }

    resendSmsVerification = flow(function* () {
        if (this.hasEndpoint("sms")) {
            const smsEndPoint = this.getSmsEndpoint();

            if (smsEndPoint.hasOwnProperty("Active") && isFalse(smsEndPoint.Active)) {
                this.updateEndpoint(smsEndPoint);
            } else {
                console.error("SMS already verified.");
            }
        } else {
            console.error("No SMS number has been added.");
        }
    }.bind(this));

    saveEndpoints = flow(function* () {
        this.state = States.pending;

        for (const [protocol, value] of Object.entries(this.formData)) {
            if (value !== "" && value !== null) {
                let endpoint = this._findOrBuildEndpoint(protocol);
                endpoint.EndpointAddress = value;

                yield this.persistEndpoint(endpoint, true);

                if (this.state === States.error) {
                    break;
                }
            }
        }

        if (this.state === States.pending) {
            try {
                this._loadProfile();

                AnnouncerStore.announce('Contact information has been added.');
            }
            catch (error) {
                this.state = States.error;
            }
        }
    }.bind(this));

    updateEndpoints = flow(function* () {
        this.state = States.pending;

        let endpointsToUpdate = [];
        let endpointsToDelete = [];
        for (const [protocol, value] of Object.entries(this.formData)) {
            let endpoint = this._findOrBuildEndpoint(protocol);

            let existingAddress = protocol === "email" ? endpoint.EndpointAddress : phoneDigits(endpoint.EndpointAddress);

            if (value !== existingAddress) {
                if (isEmpty(value)) {
                    if (!isFalse(endpoint.hasOwnProperty("EndpointID")) && this.hasEndpoint(protocol)) {
                        endpointsToDelete.push({ ...endpoint});
                    }
                }
                else {
                    let toUpdate = { ...endpoint};
                    toUpdate.EndpointAddress = value;
                    endpointsToUpdate.push(toUpdate);
                }
            }
        }

        let anyEndpointsChanged = false;

        try {
            for (const index in endpointsToDelete) {

                yield api.deleteProfileEndpoint(endpointsToDelete[index]);
                if (this.state === States.error) {
                    return;
                }
                anyEndpointsChanged = true;
            }

            for (const index in endpointsToUpdate) {
                yield this.persistEndpoint(endpointsToUpdate[index]);
                if (this.endpointTransaction.state === States.error) {
                    break;
                }
                anyEndpointsChanged = true;
            }

            if (this.state === States.error) {
                return;
            }

            // if the general state is fine but the endpoint transaction had a recognized error,
            // mark the general state as done and return
            if (this.endpointTransaction.state === States.error) {
                this.state = States.done;
                return;
            }

            if (appStore.isDebug()) {
                console.log("ProfileStore:all endpoints updated in updateEndpoint")
            }

            this.setShowEndpointModal(false);

            AnnouncerStore.announce('Contact information has been updated.');
        }
        catch (error) {
            this.state = States.error;
        }

        if (anyEndpointsChanged) {
            events.emit(NotifyEvents.subscriptionsChanged);
            this._loadProfile();
        }

    }.bind(this));

    isSaveEndpointsDisabled() {
        let isFormDataEmpty = isEmpty(this.formData);
        let isFormDataValuesEmpty = (Object.values(this.formData).filter(v => v !== "" && v !== null && v !== undefined).length === 0);

        let hasInvalidValue = false;

        // Has the input value been updated
        let hasSavedValueNotChanged = true;

        for (const [protocol, value] of Object.entries(this.formData)) {
            let inputValue = value ? value.trim() : "";
            let validRegEx;
            let initialValue;
            switch (protocol) {
                case "email":
                    validRegEx = RegEx.email;
                    initialValue = this.initialData["email"] ? this.initialData["email"].trim() : "";
                    break;
                case "sms":
                    validRegEx = RegEx.phone;
                    inputValue = inputValue ? phoneDigits(inputValue) : "";
                    initialValue = this.initialData["sms"] ? this.initialData["sms"].trim() : "";
                    break;
            }
            let isValueInvalid = isFalse(validRegEx.test(inputValue));
            let isValueEmpty = (inputValue === "" || inputValue === null);

            let isInvalid = (isValueEmpty ? false : isValueInvalid);

            hasSavedValueNotChanged = (hasSavedValueNotChanged && initialValue === inputValue);

            if (isInvalid) {
                hasInvalidValue = true;
                break;
            }
        }

        return isFormDataEmpty || hasInvalidValue || isFormDataValuesEmpty || hasSavedValueNotChanged;
    }

    getSmsEndpoint() {
        return this._findOrBuildEndpoint("sms");
    }

    getEmailEndpoint() {
        return this._findOrBuildEndpoint("email");
    }

    /**
     * Return the existing endpoint for the given protocol.
     * If none found, return a stub with the protocol set.
     *
     */
    _findOrBuildEndpoint(protocol) {
        let endpoint = {};

        if (this.profile.Person.hasOwnProperty("Endpoints") && this.profile.Person.Endpoints) {
            //Filter based on protocol
            const endpoints = this.profile.Person.Endpoints.filter(endpoint => endpoint.Protocol === protocol);
            if (isFalse(isEmpty(endpoints))){
                // Sort based on lastModified. This is being done because until now the backend allowed multiple endpoints
                // of the same type to be added and we needed a way to pick one instead of showing all of them.
                // A check has also been added in the backend to not allow multiple endpoints of the same type to be
                // created in the future.
                 endpoints.sort((a,b) => {
                    if (a.LastModified < b.LastModified) return -1;
                    if (a.LastModified > b.LastModified) return 1;
                    return 0;
                });
                 endpoint=endpoints[0];
            }
        }

        if (isEmpty(endpoint)) {
            endpoint = {
                "Protocol": protocol
            };

            let surrogateID;
            if (this.profile.Person.hasOwnProperty("SurrogateID") && this.profile.Person.SurrogateID) {
                surrogateID = this.profile.Person.SurrogateID;
            } else {
                surrogateID = `${appStore.getUserName()}@washington.edu`;
            }

            endpoint.OwnerID = surrogateID;
            endpoint.SubscriberID = surrogateID;
        }

        return endpoint;
    }

    hasProfile() {
        return isFalse(isEmpty(this.profile));
    }

    hasEndpoints() {
        return (
            this.profile.hasOwnProperty("Person")
            &&
            this.profile.Person.hasOwnProperty("Endpoints")
            &&
            this.profile.Person.Endpoints.length > 0
        );
    }

    /**
     * Returns utmost two endpoints (1 sms, 1 email) if defined. The end points are sorted based on lastModified. The
     * most recent ones are returned. This is being done since until now the backend allowed multiple endpoints of the
     * same type to be added and we needed a way to pick one instead of showing all of them. A check has also been added
     * in the backend to not allow multiple endpoints of the same type to be created in the future.
     *
     * @returns {Array}
     */
    getEndpoints() {
        if ( this.hasEndpoints() ) {
            let endpoints = [...this.profile.Person.Endpoints];
            if (isFalse(isEmpty(endpoints))){
                //sort based on lastModified
                endpoints.sort((a,b) => {
                    if (a.LastModified < b.LastModified) return -1;
                    if (a.LastModified > b.LastModified) return 1;
                    return 0;
                });
            }

            const emailEndpoint = endpoints.find(endpoint => endpoint.Protocol === "email");
            const smsEndpoint = endpoints.find(endpoint => endpoint.Protocol === "sms");


            let profileEndPoints = [];

            if(emailEndpoint) profileEndPoints.push(emailEndpoint);

            if(smsEndpoint) profileEndPoints.push(smsEndpoint);

            return profileEndPoints;
        } else {
            return [];
        }
    }

    hasEndpoint(protocol) {
        if ( this.hasProfile() && this.hasEndpoints() ) {
            let endpoint = this.profile.Person.Endpoints.find(endpoint => endpoint.Protocol === protocol);

            return !isEmpty(endpoint);
        } else {
            return false;
        }
    }

    toggleEndpointModal() {
        this.setShowEndpointModal(!this.showEndpointModal);
    }

    setShowEndpointModal(value) {
        this.showEndpointModal = value;

        // reset form data when the modal is closed
        if (!this.showEndpointModal) {
            this.resetFormData();
            this.updatedUnblockState = false;
        }
    }

    isEmailEndpointBlocked() {
        const emailEndpoint = this.getEmailEndpoint();
        if ( !isEmpty(emailEndpoint) ) {
            return emailEndpoint.hasOwnProperty("EmailBlocks") ? emailEndpoint.EmailBlocks.length > 0 : false
        } else {
            return false;
        }
    }

    unblockEmail = flow(function* (callback) {
        if ( this.hasEndpoint("email") && this.isEmailEndpointBlocked() ) {
            this.state = States.pending;

            try {
                const emailEndpoint = this.getEmailEndpoint();
                yield api.removeBlockedEmailEndpoint(emailEndpoint.EndpointAddress);
                this.updatedUnblockState = true;
                this._loadProfile();
                events.emit(NotifyEvents.subscriptionsChanged);
                if (callback && typeof callback === "function") {
                    callback();
                }
            } catch (e) {
                this.state = States.error;
            }
        }
    }.bind(this));

    blockEmail = flow(function* (callback) {
        if ( this.hasEndpoint("email") && !this.isEmailEndpointBlocked() ) {
            this.state = States.pending;

            try {
                const emailEndpoint = this.getEmailEndpoint();
                yield api.createBlockedEmailEndpoint(emailEndpoint.EndpointAddress);
                this._loadProfile();
                events.emit(NotifyEvents.subscriptionsChanged);
                if (callback && typeof callback === "function") {
                    callback();
                }
            } catch (e) {
                this.state = States.error;
            }
        }
    }.bind(this));
}

export default new ProfileStore();