'use strict';

import { observable, configure, flow, action, observe, makeObservable } from 'mobx';
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import profileStore from "./ProfileStore";
import subscriptionStore from "./SubscriptionStore";
import api from "./utils/Api";
import {getAggregateState} from "@uw-it-sis/lib-react/lib/StateUtils";
import events from "@uw-it-sis/lib-react/lib/Events";
import {NotifyEvents} from "./utils/Constants";
import AnnouncerStore from "@uw-it-sis/lib-react/lib/AnnouncerStore";

class SubscriptionFormStore {

    state = States.initial;

    formData = {
        email: false,
        sms: false,
        errorMessage: ""
    };

    constructor() {
        makeObservable(this, {
            state: observable,
            formData: observable,
            mount: action,
            checkDataLoadStates: action,
            toggleFormValue: action
        });

        this.dataLoadStates = {
            subscriptionStore: States.initial,
            internalProcessing: States.initial
        };
    }

    mount(subscription) {
        this.dataLoadStates.internalProcessing = States.pending;
        this.checkDataLoadStates();

        this.subscription = subscription;

        this.initialEmail = subscription.endpoints.some( (e) => {return e.type === "email"} );
        this.initialSms = subscription.endpoints.some( (e) => {return e.type === "sms"} );

        this.formData = {
            email: this.initialEmail,
            sms: this.initialSms
        }

        this.subscriptionDisposer = observe(subscriptionStore, "state", function (change) {
            this.dataLoadStates.subscriptionStore = change.newValue;
            this.checkDataLoadStates();
        }.bind(this));

        // it's expected the subscription store is already initially loaded by the time we reach here
        this.dataLoadStates.subscriptionStore = subscriptionStore.state;

        this.dataLoadStates.internalProcessing = States.done;
        this.checkDataLoadStates();
    }

    unmount() {
        this.subscriptionDisposer();
    }

    saveChanges = flow(function* () {
        this.dataLoadStates.internalProcessing = States.pending;
        this.checkDataLoadStates();

        if (!this.formData.email && !this.formData.sms) {
            // if editing a subscription, at least one endpoint type must be selected
            // if creating a new subscription, none selected is a no-op
            this.formData.errorMessage = "At least one contact method must be selected. To remove completely, use the unsubscribe button";
        }
        else {
            // create subscriptions for any missing endpoints
            let addSubscription = false;
            let endpointsForCreate = [];
            let dataChanged = false;

            if (!this.initialEmail && this.formData.email) {
                endpointsForCreate.push({
                    EndpointID: profileStore.profile.Person.Endpoints.find( (e) => {return e.Protocol === "email"}).EndpointID,
                    Protocol: "email"
                });
                addSubscription = true;
            }

            if (!this.initialSms && this.formData.sms) {
                endpointsForCreate.push({
                    EndpointID: profileStore.profile.Person.Endpoints.find( (e) => {return e.Protocol === "sms"}).EndpointID,
                    Protocol: "sms"
                });
                addSubscription = true;
            }

            if (addSubscription) {
                for (let endpoint of endpointsForCreate) {
                    let personSubscriberId = profileStore.profile.Person.SurrogateID;

                    let apiData = {
                        "Channel": {
                            "ChannelID": this.subscription.channelId
                        },
                        "Endpoint": {
                            ...endpoint,
                            "SubscriberID": personSubscriberId
                        }
                    };

                    try {
                        yield api.createSubscription(apiData);

                        AnnouncerStore.announce(`Notification for ${this.subscription.courseCode} in ${this.subscription.quarter} ${this.subscription.year} has added ${endpoint.Protocol}`);
                    } catch (error) {
                        this.dataLoadStates.internalProcessing = States.error;
                        this.checkDataLoadStates();
                        return;
                    }
                }

                dataChanged = true;
            }

            // remove subscription for any endpoints that are no longer selected
            // at least one must still exist, so at most one is removed
            let endpointProtocolForDelete = undefined;
            if (this.initialEmail && !this.formData.email) {
                endpointProtocolForDelete = "email";
            }
            else if (this.initialSms && !this.formData.sms) {
                endpointProtocolForDelete = "sms";
            }

            if (endpointProtocolForDelete !== undefined) {

                let subscriptionId = subscriptionStore.getSubscriptionIdByChannelIdAndProtocol(
                    this.subscription.channelId, endpointProtocolForDelete);

                try {
                    yield api.deleteSubscription(subscriptionId);

                    AnnouncerStore.announce(`Notification for ${this.subscription.courseCode} in ${this.subscription.quarter} ${this.subscription.year} has removed ${endpointProtocolForDelete}`);
                }
                catch (error) {
                    this.dataLoadStates.internalProcessing = States.error;
                    this.checkDataLoadStates();
                    return;
                }

                dataChanged = true;
            }

            // re-load subscription data if anything changed
            if (dataChanged) {
                events.emit(NotifyEvents.subscriptionUpdated);
                subscriptionStore.mount();
            }
        }

        this.dataLoadStates.internalProcessing = States.done;
        this.checkDataLoadStates();
    }.bind(this));

    /**
     * Check aggregate data load states and update the overall state if necessary.
     */
    checkDataLoadStates() {
        this.state = getAggregateState(this.dataLoadStates);
    }

    toggleFormValue(formValue) {
        this.formData[formValue] = !this.formData[formValue];

        // if any value is set to true, remove any error message
        if (this.formData[formValue]) {
            this.formData.errorMessage = "";
        }
    }

}

export default SubscriptionFormStore;