'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";
import {parse} from 'qs';

import Spinner from "react-bootstrap/Spinner";

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import {States} from "@uw-it-sis/lib-react/lib/AppConstants";
import {AppContext} from '@uw-it-sis/lib-react/lib/AppContext';
import {withRouter} from "@uw-it-sis/lib-react/lib/WithRouter";
import {isEmpty} from "@uw-it-sis/lib-react/lib/Utils";
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import SubscriptionStore from './SubscriptionStore';
import SubscriptionsTerm from "./SubscriptionsTerm";
import {RegEx} from "./utils/Constants";

class Subscriptions extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.props = props;
        this.store = SubscriptionStore;

        this._buildComponent = this._buildComponent.bind(this);
    }

    componentDidMount() {
        const requiredProps = ['year', 'quarter', 'sln'];
        let hasRequiredProps = false;
        let isSlnFormat = false;
        let autoSubscribe;
        let location = this.context.history.location;
        // check to see if an incoming section for auto add is specified
        // Expected location is:  #/?sln=23356&year=2022&quarter=autumn
        // Section is subscribed to all available endpoints
        let hashArr = location.hash.split("?");
        if (location.hash && hashArr.length > 1) {
            // Don't get tripped up by the states param
            let params = parse(hashArr[1]);
            delete params.states
            hasRequiredProps = requiredProps.every(key => Object.keys(params).includes(key));
            isSlnFormat = RegEx.sln.test(params.sln);
            if (hasRequiredProps && isSlnFormat) {
                autoSubscribe = params;
                this.context.history.replace({pathname: location.pathname, hash: hashArr[0]})
            }
        }

        // check to see if an incoming term and SLN is specified to pre-populate a term's form
        // The expected location hash pattern is:  #/course/[four digit year]/[lowercase quarter name]/[SLN] #/course/2022/autumn/23356
        let initialFormData = undefined;

        if ( this.props.hasOwnProperty("params") && !isEmpty(this.props.params) ) {
            hasRequiredProps = requiredProps.every(key => Object.keys(this.props.params).includes(key));
            isSlnFormat = RegEx.sln.test(this.props.params.sln)

            if (hasRequiredProps && isSlnFormat) {
                initialFormData = {
                    year: this.props.params.year,
                    quarter: this.props.params.quarter,
                    sln: this.props.params.sln
                };

                // clean up the URL bar once we have memorized the data
                this.context.history.replace({pathname: location.pathname, hash: "#/"})
            }
        }

        this.store.mount(autoSubscribe, initialFormData);
    }

    componentWillUnmount() {
        this.store.unmount();
    }

    _buildComponent() {
        const subscriptionsByTerm = this.store.getSubscriptionsByTerm(this.store.subscriptions);
        const terms = this.store.publishedTerms.map((term, index) => {
            let subscriptionsList = [];
            if (subscriptionsByTerm.hasOwnProperty(term.id) && subscriptionsByTerm[term.id].length > 0) {
                subscriptionsList = subscriptionsByTerm[term.id];
            }

            let incomingSln = undefined;

            if (this.store.initialFormData) {
                if (term.year === this.store.initialFormData.year &&
                    term.codeName.toLowerCase() === this.store.initialFormData.quarter) {
                    incomingSln = this.store.initialFormData.sln;
                }
            }

            return (
                <SubscriptionsTerm term={term} subscriptions={subscriptionsList} incomingSln={incomingSln} key={index} />
            );
        })

        const loaderSpinner = <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        />;

        return (
            <Loader show={this.store.state === States.pending}>
                <div className="space-y-4">
                    {terms}
                </div>
            </Loader>
        );

    }

    render() {
        // Use 'spinner' animation for first time load of course section data
        let pendingComponent = "dots";
        // If data is already present...
        if ( this.store.hasData() ) {
            pendingComponent = this._buildComponent;
        }

        return (
            <SimpleState
                state={this.store.state}
                pendingComponentBuilder={pendingComponent}
                doneComponentBuilder={this._buildComponent}
                name="Subscriptions"
            />
        );
    }
}

export default withRouter(observer(Subscriptions));