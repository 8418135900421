'use strict';

import { configure, observable, flow, action, makeObservable } from 'mobx';
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import TermUtils from "@uw-it-sis/lib-react/lib/TermUtils";
import {getAggregateState} from "@uw-it-sis/lib-react/lib/StateUtils";
import events from "@uw-it-sis/lib-react/lib/Events";
import AnnouncerStore from "@uw-it-sis/lib-react/lib/AnnouncerStore";
import {isEmpty} from "@uw-it-sis/lib-react/lib/Utils";

import {NotifyEvents} from "./utils/Constants";
import api from "./utils/Api";
import profileStore from "./ProfileStore";

class SubscriptionStore {

    state = States.initial;

    subscriptions = [];

    constructor() {
        makeObservable(this, {
            state: observable,
            subscriptions: observable,
            mount: action,
            deleteByChannel: action,
            checkDataLoadStates: action
        });

        this.dataLoadStates = {
            publishedTermsStore: States.initial,
            internalProcessing: States.initial
        };
        this.publishedTerms = [];
        this.initialized = false;
        this.autoSubscribe = {};
        this.initialFormData = {};
    }

    mount(autoSubscribe, initialFormData) {
        this.initialized = false;

        events.listen(NotifyEvents.subscriptionsChanged, () => {
            this.initialFormData = {};
            this.loadSubscriptions();
        });

        if (autoSubscribe) {
            this.autoSubscribe = autoSubscribe;
        }

        if (initialFormData) {
            this.initialFormData = initialFormData;
        }

        this.loadPublishedTerms();

    }

    unmount() {
        events.remove(NotifyEvents.subscriptionsChanged, () => {
            this.loadSubscriptions();
        });
    }

    loadPublishedTerms = flow(function* () {
        this.dataLoadStates.publishedTermsStore = States.pending;
        this.checkDataLoadStates();

        try {
            this.publishedTerms = yield api.getTerms("published");

            if (appStore.isDebug()) {
                console.log("SubscriptionStore:loadPublishedTerms completed loading published terms")
            }

            this.dataLoadStates.publishedTermsStore = States.done;

            this.loadSubscriptions();

        }
        catch (error) {
            console.log(error);
            this.dataLoadStates.publishedTermsStore = States.error;
            return;
        }

        this.checkDataLoadStates();
    }.bind(this));


    loadSubscriptions = flow(function* () {
        this.dataLoadStates.internalProcessing = States.pending;
        this.checkDataLoadStates();

        try {
            let apiData = yield api.getSubscriptions(this.publishedTerms);

            this.subscriptions = apiData.subscriptionList;
            this.courseSubscriptionsByChannelId = apiData.subscriptionsByChannelId;

            if (appStore.isDebug()) {
                console.log("SubscriptionStore:loadSubscriptions completed retrieving subscriptions")
            }

            if (isEmpty(this.autoSubscribe)) {
                this.dataLoadStates.internalProcessing = States.done;
            } else {
                this.addSubscription();
            }

        }
        catch (error) {
            console.log(error);
            this.dataLoadStates.internalProcessing = States.error;
            return;
        }

        this.checkDataLoadStates();
    }.bind(this));

    addSubscription = flow(function* () {
        if (appStore.isDebug()) {
            console.log("SubscriptionStore:addSubscription started for autoSubscribe");
        }

        if (isEmpty(this.autoSubscribe)) {
            this.dataLoadStates.internalProcessing = States.done;
        } else {
            try {
                let subscription = yield api.searchChannels(this.autoSubscribe.sln, this.autoSubscribe.year, this.autoSubscribe.quarter);

                if (subscription.length > 0) {
                    let subscriptionItem = subscription[0];
                    if (appStore.isDebug()) {
                        console.log("SubscriptionStore:addSubscription section for autoSubscribe found");
                        console.log(subscription);
                    }

                    let endpointsForCreate = profileStore.getEndpoints();

                    for (let endpoint of endpointsForCreate) {
                        let personSubscriberId = profileStore.profile.Person.SurrogateID;

                        let apiData = {
                            "Channel": {
                                "ChannelID": subscriptionItem.channelId
                            },
                            "Endpoint": {
                                ...endpoint,
                                "SubscriberID": personSubscriberId
                            }
                        };

                        yield api.createSubscription(apiData);
                    }

                    AnnouncerStore.announce(`Notification for ${subscriptionItem.courseCode} has been added to ${subscriptionItem.quarter} ${subscriptionItem.year}`);
                } else {
                    if (appStore.isDebug()) {
                        console.log("SubscriptionStore:addSubscription section for autoSubscribe not found");
                    }
                }

                this.autoSubscribe = {};
                // reload subscriptions with newly added
                this.loadSubscriptions();
            } catch (error) {
                console.log(error);
                this.dataLoadStates.internalProcessing = States.error;
                return;
            }
        }

        this.checkDataLoadStates();
    }.bind(this));

    getSubscriptionsByTerm(subscriptions) {
        // Group subscriptions by quarter and year
        let subscriptionsByTermObj = subscriptions.reduce((groups, subscription) => {
            const termName = ["quarter", "year"].map(value => subscription[value]).join(" ");
            const termId = TermUtils.makeTermIdFromTermName(termName);
            groups[termId] = groups[termId] || [];
            groups[termId].push(subscription);
            return groups;
        }, {});

        // Sort the terms
        return Object.keys(subscriptionsByTermObj).sort().reduce((obj, key) => {
            obj[key] = subscriptionsByTermObj[key];
            return obj;
        }, {});
    }

    getSubscriptionIdByChannelIdAndProtocol(channelId, protocol) {
        let result = undefined;
        if (this.courseSubscriptionsByChannelId[channelId]) {
            result = this.courseSubscriptionsByChannelId[channelId][protocol];

            if (result !== undefined) {
                result = result.SubscriptionID;
            }
        }

        return result;
    }

    /**
     * This method will delete both the email and sms subscription for a channel,
     * which will effectively delete the subscription completely.
     * After all delete promises have yielded, all subscriptions will be re-loaded
     *
     */
    async deleteByChannel(subscription) {
        let channelId = subscription.channelId;

        this.state = States.pending;

        let subscriptionIds = [];

        let subId = this.getSubscriptionIdByChannelIdAndProtocol(channelId, "email");
        if (subId) {
            subscriptionIds.push(subId);
        }

        subId = this.getSubscriptionIdByChannelIdAndProtocol(channelId, "sms");
        if (subId) {
            subscriptionIds.push(subId);
        }

        let promises = [];
        for (let subscriptionId of subscriptionIds) {
            if (subscriptionId) {
                try {
                    let promise = api.deleteSubscription(subscriptionId);

                    promises.push(promise);
                }
                catch (error) {
                    this.state = States.error;
                    // short cut out if there's an error
                    return;
                }
            }
        }

        await Promise.all(promises).then(() => {
            this.loadSubscriptions();

            AnnouncerStore.announce(`Notification for ${subscription.courseCode} has been removed from ${subscription.quarter} ${subscription.year}`);
        });
    }

    /**
     * Check aggregate data load states and update the overall state if necessary.
     */
    checkDataLoadStates() {
        this.state = getAggregateState(this.dataLoadStates);

        // Once the initial data has been loaded, disconnect the observing of their store states
        if (this.state === States.done) {
            this.initialized = true;
        }
    }

    hasData() {
        return this.initialized;
    }
}

export default new SubscriptionStore();