'use strict';

import {capitalize, isFalse} from '@uw-it-sis/lib-react/lib/Utils';
import {RegEx} from "./Constants";

/**
 * Parse a surrogate course section identifier
 * Surrogate IDs are expected to be in the form: 2021,spring,law a,595,d
 *
 * @param surrogateId
 * @return the formatted elements of the surrogate id represented in an object
 */
export function parseSurrogateId(surrogateId) {
    let tokens = surrogateId.split(',');

    let curriculumAbbr = tokens[2].toUpperCase().trim();

    return {
        year: tokens[0],
        quarter: capitalize(tokens[1]),
        courseCode: `${curriculumAbbr} ${tokens[3]}`,
        courseCurriculumAbbr: curriculumAbbr,
        courseNumber: tokens[3],
        activityCode: tokens[4].toUpperCase()
    };
}

/**
 * Restricts input field to the maxlength value on the input.
 * @param e The onInput js event
 * @return true if the value length is less then or equal to maxlength, else ignore any attempt to input more then the maxlength
 */
export function handleInputMaxLength(e) {
    let value = e.target.value;
    if ( value.length <= e.target.maxLength ) {
        return true;
    } else {
        e.target.value = value.substring(0, value.length - 1);
    }
}

/**
 * Restricts input field to just digits and select/copy/cut/paste/tab/esc/arrow actions
 * @param e The js event from keydown
 * @return true if key is allowed, if not, then preventDefault
 */
export function handleInputNumbersOnly(e) {
    // Is the keydown a copy, cut, paste, or select all action
    let charCode = String.fromCharCode(e.which).toLowerCase();
    let isAllowedAction = ( (e.ctrlKey || e.metaKey) && ['a', 'x', 'c', 'v'].includes(charCode));
    // Is the keydown a digit, backspace, esc, left or right arrow, or other ctrl/cmd key
    let isAllowedCode = [0, 8, 9, 17, 27, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 93, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105].includes(e.which);
    // If the keydown is an allowed action or allowed key, continue, if not prevent default
    if ( isAllowedCode || isAllowedAction ) {
        return true;
    } else {
        e.preventDefault();
    }
}

/**
 * Accepts a string of 10 digits prefixed with the US country code (+1) and formats then into a formatted phone number
 * @param number - string of 10 digits prefixed with +1
 * @return {string|*}
 */
export function phoneFormat(number) {
    if (number) {
        const phoneData = number.match(RegEx.number);

        if (phoneData && phoneData.length === 4) {
            return `(${phoneData[1]}) ${phoneData[2]}-${phoneData[3]}`;
        } else {
            return number;
        }
    } else {
        return number;
    }

}

/**
 * Accepts a formatted phone number and strips away any special characters, spaces, and international code (+1)
 * @param string - a formatted phone number
 * @return {string|null} - 10 digits that make up a US phone number
 */
export function phoneDigits(string) {
    if (string && string !== "" && string !== null) {
        let digits = string.replace(/\D/g,'');
        if ( digits.length === 11 && string.startsWith('+') && digits.startsWith('1') ) {
            return digits.substring(1);
        } else if ( digits.length <= 10 ) {
            return digits;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

/**
 * Return a set of flags set to true if any activity within the given list has at least one of those flags set.
 *
 * @param subscriptions list of activities
 * @return {{enrollRestriction: boolean, addCode: boolean}}
 */
export function getSectionLegendFlags(subscriptions) {
    let legends = {
        enrollRestriction: false,
        addCode: false
    };

    for (let subscription of subscriptions) {
        // if the activity has a state key, check that it is active
        if (!subscription.section.stateKey || subscription.section.stateKey.toLowerCase() === 'active') {

            let activityAddCode = subscription.section.addCodeRequired || subscription.section.independentStudy;

            // check if already true, if a single activity has addCodeRequired or independentStudy, legend show is true
            if (isFalse(legends.addCode)) {
                legends.addCode = activityAddCode;
            }

            // check if already true, the enrollment restriction isn't from an activity add code
            // and the activity has the enroll restriction field set to true, legend show is true
            if (isFalse(legends.enrollRestriction) && isFalse(activityAddCode)) {
                legends.enrollRestriction = subscription.section.enrollRestriction;
            }

        }

        if ( Object.keys(legends).map(key => legends[key]).every(value => value) ) { break; }
    }

    return legends;
}