'use strict';

import React, {Component} from 'react';
import {observer} from 'mobx-react';
import classnames from 'classnames';
import {Link} from "react-router-dom";

import { FaRegCircleCheck, FaRegCircle, FaCircleCheck, FaCircleInfo } from "react-icons/fa6";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import DocumentTitle from "@uw-it-sis/lib-react/lib/DocumentTitle";
import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import Loader from "@uw-it-sis/lib-react/lib/Loader";
import {States} from "@uw-it-sis/lib-react/lib/AppConstants";

import profileStore from "./ProfileStore";

class NotifySubscriptionStatus extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.store = profileStore;

        this.state = {
            changed: false
        }
    }

    componentDidMount() {
        this.store.mount();
    }

    unsubscribe = () => {
        this.store.blockEmail(this.toggleChanged);
    }

    subscribe = () => {
        this.store.unblockEmail(this.toggleChanged);
    }

    toggleChanged = () => {
        this.setState({changed: true})
    }

    _buildPageComponent = () => {
        let isBlocked = this.store.isEmailEndpointBlocked();

        let emailAddress = this.store.getEmailEndpoint().EndpointAddress;

        let pageHeading, body, buttonAction, footer;
        if (!isBlocked) {
            pageHeading = `Block ${emailAddress}?`
            body =
                <>
                    <p className="mt-4">This email address will not receive <em>any</em> email notifications from
                        Notify.UW.</p>
                    <p>To unsubscribe from just one SLN (course section), <Link to={'/'}>go to Notify.UW</Link> and
                        change the settings for that section.</p>
                </>
            buttonAction =
                <ButtonToolbar className="space-x-5">
                    <Button variant="link" href="/notify" className="px-0">No, do not block this email address</Button>
                    <Button onClick={this.unsubscribe}>Yes, block this email address</Button>
                </ButtonToolbar>

        } else {
            if (!this.state.changed) {
                pageHeading = `You have blocked ${emailAddress}`
                buttonAction =
                    <p>Your email address is already blocked. It will not receive <em>any</em> email notifications from
                        Notify.UW.</p>
                footer =
                    <p className="mt-4">
                        <Link to={'/'}>Visit Notify.UW</Link> to unblock this address or to subscribe to
                        notifications with a different email address.
                    </p>
            } else {
                pageHeading = `You have blocked ${emailAddress}`
                buttonAction =
                    <Alert variant="success" className="d-inline-flex align-items-center space-x-2 mb-0">
                        <FaCircleCheck className="fs-6" aria-hidden={true}/>
                        <span>This email address has been blocked and will not receive any notifications from Notify.UW.</span>
                    </Alert>
                footer =
                    <p className="mt-4">
                        <Link to={'/'}>Visit Notify.UW</Link> to unblock this address or to subscribe to
                        notifications with a different email address.
                    </p>
            }
        }

        /*

        let subscribedSelected = !isBlocked;
        let unsubscribedSelected = isBlocked;

        let subscribeAttr = {
            role: "radio",
            "aria-label": "Subscribed",
            "aria-checked": subscribedSelected,
            bg: (subscribedSelected ? "light-beige":null),
            border: (subscribedSelected ? "dark-beige":"border"),
            tabIndex: (subscribedSelected ? -1:0),
            className: classnames("card-clickable", {"active shadow": subscribedSelected}, {"text-muted": unsubscribedSelected})
        };

        if (unsubscribedSelected) {
            subscribeAttr.onClick = () => this.subscribe()
        }

        let unsubscribeAttr = {
            role: "radio",
            "aria-label": "Unsubscribed",
            "aria-checked": unsubscribedSelected,
            bg: (unsubscribedSelected ? "light-beige":null),
            border: (unsubscribedSelected ? "dark-beige":"border"),
            tabIndex: (unsubscribedSelected ? -1:0),
            className: classnames("card-clickable", {"active shadow": unsubscribedSelected}, {"text-muted": subscribedSelected})
        };

        if (subscribedSelected) {
            unsubscribeAttr.onClick = () => this.unsubscribe()
        }

        let form =
            <Row className="justify-content-md-center">
                <Col xs={6} md={3}>
                    <Card {...subscribeAttr}>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <div className="lead fw-bold">Subscribed</div>
                            {subscribedSelected ? <FaRegCircleCheck className="fs-4 text-success" aria-hidden={true} /> : <FaRegCircle className="fs-4" aria-hidden={true} />}
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                You will receive email notifications for sections where you have selected email as a method of delivery.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3}>
                    <Card {...unsubscribeAttr}>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <div className="lead fw-bold">Unsubscribed</div>
                            {unsubscribedSelected ? <FaRegCircleCheck className="fs-4 text-success" aria-hidden={true} /> : <FaRegCircle className="fs-4" aria-hidden={true} />}
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                You will not receive email notifications even if you have selected email as a method of delivery.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

         */



        return (
            <>
                <Loader show={this.store.state === States.pending}>
                    <h1>{pageHeading}</h1>
                    {body}
                    <div className="py-4">
                        {buttonAction}
                        {footer}
                    </div>
                </Loader>
                {/*
                <div role="radiogroup" aria-labelledby="subscribe-state">
                    <div id="subscribe-state" className="text-center h2 mb-4">Update {emailAddress} to:</div>
                    <Loader show={this.store.state === States.pending}>
                        {form}
                    </Loader>
                    {messaging}
                </div>
                */}
            </>
        )
    }

    render() {
        let component;

        if (this.store.hasProfile()) {
            component = this._buildPageComponent();
        } else {
            component =
                <SimpleState
                    pendingComponentBuilder="dots"
                    state={this.store.state}
                    doneComponentBuilder={this._buildPageComponent}
                    name="NotifySubscriptionStatus" />
        }
        return (
            <DocumentTitle title="Update Subscription - Notify.UW">
                {component}
            </DocumentTitle>
        );
    }

}

export default observer(NotifySubscriptionStatus);