'use strict';

import { configure, observable, observe, action, makeObservable } from 'mobx';
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {getAggregateState} from "@uw-it-sis/lib-react/lib/StateUtils";

import profileStore from './ProfileStore';

class NotifyStore {

    state = States.initial;

    constructor() {
        makeObservable(this, {
            state: observable,
            mount: action,
            checkDataLoadStates: action
        });

        this.dataLoadStates = {
            profileStore: States.initial,
            internalProcessing: States.initial
        };
    }

    mount() {
        this.dataLoadStates = {
            internalProcessing: States.pending,
            profileStore: States.pending,
        }

        this.profileDisposer = observe(profileStore, "state", function (change) {
            this.dataLoadStates.profileStore = change.newValue;
            this.checkDataLoadStates();
        }.bind(this));

        profileStore.mount();

        this.dataLoadStates.internalProcessing = States.done;

        this.checkDataLoadStates();
    }

    unmount() {
        this.profileDisposer();
    }

    /**
     * Check aggregate data load states and update the overall state if necessary.
     */
    checkDataLoadStates() {
        this.state = getAggregateState(this.dataLoadStates);

        // Once the initial data has been loaded, disconnect the observing of their store states
        if (this.state === States.done) {
            this.unmount();
        }
    }

}

export default new NotifyStore();