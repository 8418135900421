'use strict';

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {FaExclamationTriangle} from "react-icons/fa";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import {isFalse, makeUniqueId} from "@uw-it-sis/lib-react/lib/Utils";
import HelpPopover from "@uw-it-sis/lib-react/lib/HelpPopover";

import {ProtocolLabels} from "./utils/Constants";
import {phoneFormat} from "./utils/Utils";
import ProfileStore from "./ProfileStore";



const EndpointCheckbox = function (props) {
    const endpoint = props.endpoint;
    let smsMessage, emailMessage;
    let isDisabled = props.disabled;
    let isChecked = props.checked;
    if ( endpoint.hasOwnProperty("Active") && isFalse(endpoint.Active) ) {
        isDisabled = true;
        smsMessage =
            <div className="fade d-flex align-items-start mt-2 mb-0 p-2 space-x-2 alert alert-warning show">
                <span className="lh-1"><FaExclamationTriangle aria-hidden={true} className="fs-6" /></span>
                <small>{ProtocolLabels[endpoint.Protocol].text} confirmation is pending. Reply to the confirmation text sent to your mobile phone. <Button variant="inline-link" onClick={ProfileStore.resendSmsVerification}>Resend the confirmation</Button></small>
            </div>
    }

    if ( endpoint.Protocol === "email" && ProfileStore.isEmailEndpointBlocked() ) {
        isDisabled = true;
        // <Button variant="inline-link" onClick={ProfileStore.unblockEmail}>Unblock {ProtocolLabels[endpoint.Protocol].text}</Button>
        emailMessage =
            <>
                <div className="fade d-inline-block py-1 px-2 alert alert-danger show ms-2 mb-0">
                    <small className="text-uppercase fw-bold">Blocked</small>
                </div>
                <HelpPopover title="Your Email Address Is Blocked" className="ms-2 fs-5 p-1" placement="top">
                    <p>You have blocked your email address, so it cannot receive any notifications from Notify.UW.</p>
                    <p>To unblock this address or to subscribe to notifications with a different email address, click
                        the "Edit Contact Methods" button in the "Your Contact Methods" section.</p>
                </HelpPopover>
            </>

    }

    let address = endpoint.EndpointAddress;
    if (endpoint.Protocol === 'sms') {
        address = phoneFormat(endpoint.EndpointAddress);
    }

    let uniqueId = makeUniqueId();

    return (
        <div>
            <Form.Check id={`option-${endpoint.Protocol}-${uniqueId}`}
                        type="checkbox"
                        className={classnames({"d-inline-block": emailMessage})}>
                <Form.Check.Input
                    name={endpoint.Protocol}
                    type="checkbox"
                    checked={isChecked}
                    onChange={props.onChange}
                    disabled={isDisabled} />
                <Form.Check.Label className="space-x-2">
                    <span className="fw-bold">{ProtocolLabels[endpoint.Protocol].text}</span>
                    <small className="text-muted form-text">{address}</small>
                </Form.Check.Label>
            </Form.Check>
            {smsMessage}
            {emailMessage}
        </div>

    )
}

EndpointCheckbox.propTypes = {
    endpoint: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool
}

EndpointCheckbox.defaultProps = {
    disabled: false,
    checked: false,
    onChange: () => {} // no-op
}

export default EndpointCheckbox;