'use strict';

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {FaRegFileAlt, FaKey} from "react-icons/fa";

import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";

import appStore from '@uw-it-sis/lib-react/lib/AppStore';
import Lists from '@uw-it-sis/lib-react/lib/Lists';
import {normalize} from "@uw-it-sis/lib-react/lib/Utils";

/**
 * Creates a component with the section code and if there are enrollment restrictions, display a popover with those restrictions
 */
const SectionCode = function(props) {
    const Tag = props.inline ? 'span' : 'div';

    let section = props.section;

    const timeMaskIndicator = props.isTimeMask ? `*` : null;
    const dupEnrollIndicator = props.isDupEnroll ? <sup>&dagger;</sup> : null;

    let sectionName = `${normalize(section.courseCode)} ${section.code}`;
    const sectionId = `section-code-${section.activityId.replace(/:/g, "-")}`;

    let type = (section.primary ? 'primary':'secondary');

    if ( ( section.enrollRestriction || section.addCodeRequired || section.independentStudy ) && section.stateKey === 'active' ) {
        let restrictions = [];
        let popoverContent = null;
        let Icon = FaRegFileAlt;
        let title = `${sectionName} has enrollment restrictions`;

        // Sections with enrollment restrictions, auth users get a link to TS, unauth get messaging
        if ( section.enrollRestriction ) {
            if (appStore.isAuthenticated() && ! appStore.isSocialUser()) {
                restrictions.push(
                    <a href={'https://sdb.admin.washington.edu/timeschd/uwnetid/sln.asp?QTRYR=' + section.qtryr + '&SLN=' + section.registrationCode}
                       target="_blank">
                        View Enrollment Restrictions
                    </a>
                );
            } else {
                restrictions.push(`${sectionName} has enrollment restrictions`);
            }
        }

        // Sections that are independent study are faculty code required messaging
        if ( section.independentStudy ) {
            restrictions.push('Faculty Code Required');
            Icon = FaKey;
            title = `Add code or faculty code required for ${sectionName}`;
        }

        // Sections that are add code required but are not independent study are add code required messaging
        if ( section.addCodeRequired && !section.independentStudy ) {
            restrictions.push('Add Code Required');
            Icon = FaKey;
            title = `Add code or faculty code required for ${sectionName}`;
        }

        // Sections that are both add/faculty code required and have enrollment restrictions
        if ( section.enrollRestriction && ( section.addCodeRequired || section.independentStudy ) ) {
            title = `Add code or faculty code required for ${sectionName} and has enrollment restrictions`;
        }

        if (restrictions.length) {
            popoverContent = <Lists data={restrictions} unstyled className="list-popover-content" />;
        }

        const [showTooltip, setShowTooltip] = useState(false);
        const [target, setTarget] = useState(null);
        const ref = useRef(null);

        const closeTooltip = () => {
            setShowTooltip(false);
        };

        const openTooltip = (event) => {
            setShowTooltip(true);
            let target;
            if (event.target.hasAttribute("role")) {
                target = event.target;
            } else {
                target = event.target.parentNode;
            }
            setTarget(target);
        };

        const handleKeyDown = (event) => {
            if ([13, 32].includes(event.which)) {
                event.preventDefault();
                openTooltip(event);
            }
        };

        const handleClose = (event) => {
            if ([13, 32].includes(event.which)) {
                event.preventDefault();
                closeTooltip(event);
            }
        }

        let restrictionPopover =
            <Popover id={`${sectionId}-popover`} className="popover-help shadow-sm">
                <Popover.Header className="fw-bold">
                    Enrollment Restrictions
                    <a id={`${sectionId}-popover-close`} className="close" onClick={closeTooltip} onKeyDown={handleClose} tabIndex="0">
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close popover</span>
                    </a>
                </Popover.Header>
                <Popover.Body>{popoverContent}</Popover.Body>
            </Popover>;

        return (
            <Tag id={sectionId} className="d-inline" ref={ref}>
                <Tag id={`${sectionId}-trigger`}
                     onClick={openTooltip}
                     onKeyDown={handleKeyDown}
                     tabIndex="0"
                     className="d-inline-flex align-items-center cursor-pointer"
                     role="button"
                     aria-haspopup="true">
                    <span className="sr-only">Course Section Code: </span>
                    <span className={'code ' + type}>{section.code}{timeMaskIndicator}{dupEnrollIndicator}</span>
                    <span className="sr-only">{title}</span>
                    <Icon title={title} className="sectionIcon text-muted" aria-hidden={true} />
                </Tag>
                <Overlay show={showTooltip}
                         target={target}
                         placement="right"
                         container={ref.current}
                         onHide={closeTooltip}
                         rootClose>
                    {restrictionPopover}
                </Overlay>
            </Tag>
        );
    } else {
        return (
            <Tag id={sectionId}>
                <span className="sr-only">Course Section Code</span>
                <span className={'code ' + type}>{section.code}{timeMaskIndicator}{dupEnrollIndicator}</span>
            </Tag>
        );
    }
};

SectionCode.propTypes = {
    section: PropTypes.object.isRequired,
    inline: PropTypes.bool,
    isTimeMask: PropTypes.bool,
    isDupEnroll: PropTypes.bool
};

SectionCode.defaultProps = {
    inline: false,
    isTimeMask: false,
    isDupEnroll: false
}

export default SectionCode;