'use strict';

import React, {Component} from 'react';
import {observer} from 'mobx-react';
import classnames from "classnames";
import { parse } from 'qs';
import PropTypes from 'prop-types';

import {FaExclamationTriangle} from "react-icons/fa";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import {States} from "@uw-it-sis/lib-react/lib/AppConstants";
import {isFalse, isTrue} from "@uw-it-sis/lib-react/lib/Utils";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import DocumentTitle from "@uw-it-sis/lib-react/lib/DocumentTitle";
import {withRouter} from "@uw-it-sis/lib-react/lib/WithRouter";
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import ProfileStore from './ProfileStore';
import ContactField from "./utils/ContactField";
import {ProtocolLabels} from "./utils/Constants";
import {phoneFormat} from "./utils/Utils";
import HelpPopover from "@uw-it-sis/lib-react/lib/HelpPopover";

class Profile extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.props = props;
        this.store = ProfileStore;

        this.toggleEndpointModal = this.toggleEndpointModal.bind(this);
        this.saveEndpoints = this.saveEndpoints.bind(this);
        this.updateEndpoints = this.updateEndpoints.bind(this);
        this._buildPage = this._buildPage.bind(this);
        this._buildProfileComponent = this._buildProfileComponent.bind(this);
    }

    componentDidMount() {
        // If the url has ?edit_contacts=true, then open the Edit Contacts modal
        const query = parse(this.props.search, { ignoreQueryPrefix: true });
        if (isTrue(query.edit_contacts)) {
            // Remove the search param from URL
            this.context.history.replace('');
            // Set modal for edit to open by default.
            this.store.setShowEndpointModal(true);
        }
    }

    toggleEndpointModal() {
        this.store.toggleEndpointModal();
    }

    unblockEmail = () => {
        this.store.unblockEmail();
    }

    _createProfileClicked(e) {
        e.stopPropagation();
        this.store.createProfile();
    }

    _updateFormData(e) {
        e.stopPropagation();
        this.store.updateFormData(e.target.name, e.target.value);
    }

    saveEndpoints(e) {
        e.preventDefault();
        if (!this.store.isSaveEndpointsDisabled()) {
            this.store.saveEndpoints();
        }
    }

    updateEndpoints(e) {
        e.preventDefault();
        if (!this.store.isSaveEndpointsDisabled()) {
            this.store.updateEndpoints();
        }
    }

    _buildPage() {
        let pageComponent;

        let introParagraph =
            <p className="intro-paragraph lead mb-4">
                Notify.UW is a seat availability notification service for UW students. It sends an email and/or SMS text notification when seats become available in the classes you sign up for.
            </p>;

        let serviceTermsLink =
            <p className="mb-0 mt-4">
                <a href="https://itconnect.uw.edu/learn/tools/notify-uw/notify-uw-frequently-asked-questions/#service" target="_blank">
                    Read the Notify.UW service agreement
                </a>
            </p>

        if (this.store.hasProfile()) {
            if (this.store.hasEndpoints()) {
                pageComponent = this._buildProfileComponent();
            } else {

                const emailError = this.store.endpointTransaction.errorMessage.email;
                const smsError = this.store.endpointTransaction.errorMessage.sms;

                pageComponent =
                    <Row className="justify-content-center mt-4">
                        <Col xs={12} sm={9} md={6}>
                            {introParagraph}
                            <Card border="border">
                                <Card.Body>
                                    <Card.Title as="h1" className="h2 bg-transparent">Your Contact Methods</Card.Title>
                                    <p>Set up at least one contact method.</p>
                                    <Form onSubmit={this.saveEndpoints}>
                                        <ContactField
                                            type="email"
                                            onChange={(e) => this._updateFormData(e)}
                                            value={this.store.getFormData('email')}
                                            errorMessage={emailError}
                                            />
                                        <ContactField
                                            type="sms"
                                            onChange={(e) => this._updateFormData(e)}
                                            value={this.store.getFormData('sms')}
                                            errorMessage={smsError}
                                            />
                                        <p className="mt-2">Reply to the confirmation text sent to your mobile phone. Message frequency will vary. Message and data rates may apply. Reply HELP for help or STOP to cancel. See&nbsp;
                                            <a href="https://www.washington.edu/online/terms/" target="_blank">Terms of Service</a>&nbsp;&&nbsp;
                                            <a href="https://www.washington.edu/online/privacy/" target="_blank">Privacy Policy</a>.</p>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className={classnames({"disabled": this.store.isSaveEndpointsDisabled()})}
                                            aria-disabled={this.store.isSaveEndpointsDisabled()}>
                                            Add
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                            {serviceTermsLink}
                        </Col>
                    </Row>;
            }
        } else {
            pageComponent =
                <Row className="justify-content-center mt-4">
                    <Col xs={12} sm={9} md={6}>
                        {introParagraph}
                        <Card border="border">
                            <Card.Body>
                                <Card.Title as="h1" className="h2 bg-transparent">Notify.UW Terms of Service Agreement</Card.Title>
                                <p>In order to use the Notify.UW service, you must read and agree to the following:</p>
                                <ul>
                                    <li>Your use of the Notify.UW service does not imply eligibility for course enrollment nor guarantee successful registration in courses.</li>
                                    <li>The Notify.UW service is an opt-in service. Your use of the service is strictly voluntary and is subject to
                                        the <a href="https://www.washington.edu/online/terms/" target="_blank">University's Website Terms and Conditions of Use</a> and <a href="https://www.washington.edu/online/privacy/" target="_blank">Online Privacy Statement</a>.</li>
                                    <li>You must not abuse this service, and violations may be subject to disciplinary action according to University policy and the UW Student Conduct Code.</li>
                                    <li>The Notify.UW service is subject to change at any time without notice.</li>
                                    <li>Unless you opt out of receiving SMS text messages, your use of the Notify.UW service constitutes your consent to share your phone number with a third party provider. The third party provider will use
                                        the information solely for the purpose of sending you SMS text messages from the University of Washington (read <a href="https://www.twilio.com/legal/privacy" target="_blank">Twilio's Privacy Policy</a>). If
                                        you do not agree to share your phone number, you can still sign up for email notifications and your information will not be shared with a third party provider. Message frequency will vary. Message and data rates may apply.
                                        Contact <a href="mailto:help@uw.edu">help@uw.edu</a> or reply HELP for help. Reply STOP to cancel.</li>
                                    <li>The third party provider is currently Twilio but may change at any time without notice.</li>
                                </ul>
                                <ButtonToolbar className="justify-content-between">
                                    <Button variant="primary" onClick={(e) => this._createProfileClicked(e)}>I Agree</Button>
                                    <Button variant="secondary" onClick={() => this.context.history.back()}>Cancel</Button>
                                </ButtonToolbar>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>;
        }

        return pageComponent;
    }

    _buildProfileComponent() {
        const loaderSpinner = <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        />;

        const storeEndpoints = this.store.getEndpoints();

        const endpoints = storeEndpoints.map(function (endpoint, i) {
            let address = endpoint.EndpointAddress;
            if (endpoint.Protocol === 'sms') {
                address = phoneFormat(endpoint.EndpointAddress);
            }

            let smsMessage;
            if ( endpoint.hasOwnProperty("Active") && isFalse(endpoint.Active) ) {
                smsMessage =
                    <div className="fade d-flex align-items-start mt-1 mb-0 p-2 space-x-2 alert alert-warning show">
                        <span className="lh-1"><FaExclamationTriangle aria-hidden={true} className="fs-6" /></span>
                        <small>{ProtocolLabels[endpoint.Protocol].text} confirmation is pending. Reply to the confirmation text sent to your mobile phone. <Button variant="inline-link" onClick={ProfileStore.resendSmsVerification}>Resend the confirmation</Button></small>
                    </div>
            }

            let emailMessage;
            if ( endpoint.Protocol === "email" && ProfileStore.isEmailEndpointBlocked() ) {
                // <Button variant="inline-link" onClick={ProfileStore.unblockEmail}>Unblock {ProtocolLabels[endpoint.Protocol].text}</Button>
                emailMessage =
                    <>
                        <div className="fade d-inline-block py-1 px-2 alert alert-danger show ms-2 mb-0">
                            <small className="text-uppercase fw-bold">Blocked</small>
                        </div>
                        <HelpPopover title="Your Email Address Is Blocked" className="ms-2 fs-5 p-1" placement="top">
                            <p>You have blocked your email address, so it cannot receive any notifications from
                                Notify.UW.</p>
                            <p>To unblock this address or to subscribe to notifications with a different email address,
                                click the "Edit Contact Methods" button in the "Your Contact Methods" section.</p>
                        </HelpPopover>
                    </>
            }

            return (
                <li key={i}
                    className={classnames("row g-0", {"align-items-start": smsMessage}, {"align-items-center": !smsMessage})}>
                    <Col xs={12} xl={4} className="text-nowrap fw-bold d-flex">{ProtocolLabels[endpoint.Protocol].combo}:</Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 11, offset: 1 }} xl={{ span: 8, offset: 0 }}>
                        <div className={classnames("fw-bold", {"d-inline-block": emailMessage}, {"text-muted": emailMessage || smsMessage})}>{address}</div>
                        {smsMessage}
                        {emailMessage}
                    </Col>
                </li>
            );
        }.bind(this));

        let emailEndPointBlocked;
        if ( ProfileStore.isEmailEndpointBlocked() ) {
            emailEndPointBlocked =
                <div className="alert alert-warning mt-n2">
                    <p className="mb-2">{this.store.getEmailEndpoint().EndpointAddress ? <>The email address <span className="fw-bold">{this.store.getEmailEndpoint().EndpointAddress}</span></> : "This email address"} has been blocked and cannot receive any email from Notify.UW.</p>
                    <Button variant="inline-link" className="fw-bold" onClick={this.unblockEmail}>Unblock this email address</Button>
                </div>
        }
        if ( this.store.updatedUnblockState ) {
            emailEndPointBlocked =
                <div className="alert alert-success mt-n2">
                    <p className="mb-0">This email address has been unblocked and can now receive notifications from Notify.UW</p>
                </div>
        }

        const showModal = this.store.showEndpointModal;
        const showLoader = this.store.endpointTransaction.state === States.pending;
        const emailError = this.store.endpointTransaction.errorMessage.email;
        const smsError = this.store.endpointTransaction.errorMessage.sms;

        return (
            <>
                <Card border="border" style={{backgroundColor: "#F5F3FE"}} className="my-5 my-lg-0">
                    <Card.Header as="h2" className="bg-transparent">
                        Your Contact Methods
                    </Card.Header>
                    <Card.Body>
                        <p>Seat availability notifications will be sent to the following email and/or mobile number.</p>
                        <ul className="list-unstyled space-y-3">
                            {endpoints}
                        </ul>
                        <Button variant="primary"
                                className="mx-auto"
                                title="Edit notification contacts"
                                onClick={this.toggleEndpointModal}>
                            Edit Contact Methods
                        </Button>
                    </Card.Body>
                </Card>
                <Modal show={showModal}
                       size="sm"
                       onHide={this.toggleEndpointModal}
                       aria-labelledby="edit-profile-modal-title"
                       centered
                       scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title id="edit-profile-modal-title" as="h3">Edit Your Contact Methods</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Loader show={showLoader}>
                            <p>At least one contact method is required to receive seat availability notifications.</p>
                            <Form className="d-grid gap-3" onSubmit={this.updateEndpoints}>
                                <ContactField
                                    type="email"
                                    onChange={(e) => this._updateFormData(e)}
                                    value={this.store.getEmailEndpoint().EndpointAddress ? this.store.getEmailEndpoint().EndpointAddress : ""}
                                    errorMessage={emailError}
                                />
                                {emailEndPointBlocked}
                                <ContactField
                                    type="sms"
                                    style={{width: "11rem"}}
                                    onChange={(e) => this._updateFormData(e)}
                                    value={this.store.getSmsEndpoint().EndpointAddress ? this.store.getSmsEndpoint().EndpointAddress : ""}
                                    errorMessage={smsError}
                                />
                                <ButtonToolbar className="mt-3 justify-content-start">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className={classnames({"disabled": this.store.isSaveEndpointsDisabled()})}
                                        aria-disabled={this.store.isSaveEndpointsDisabled()}>
                                        Update Contact Methods
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={this.toggleEndpointModal}>
                                        Cancel
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Loader>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    render() {
        let pendingComponent;
        let pageTitle = "Notify.UW";
        if (!this.store.hasProfile()) {
            pageTitle = "Terms of Service Agreement - Notify.UW";
        } else {
            if (!this.store.hasEndpoints()) {
                pageTitle = "Add Your Contact Methods - Notify.UW";
            }
        }
        if ( this.store.hasProfile() && this.store.hasEndpoints() ) {
            pendingComponent = this._buildProfileComponent;
        }
        return (
            <DocumentTitle title={pageTitle}>
                <>
                    <SimpleState
                        state={this.store.state}
                        pendingComponentBuilder={pendingComponent}
                        doneComponentBuilder={() => this._buildPage()}
                        name="EndpointsPage"/>
                </>
            </DocumentTitle>
        );
    }
}

export default withRouter(observer(Profile));